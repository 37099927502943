import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Box from "@mui/material/Box";
import { FoodContext } from "../../Context/Context";
import { useContext } from "react";
import Button from "@mui/material/Button";

const TrackedFoodList = () => {
  const context = useContext(FoodContext);
  const trackedFoodItems = context.foodListForTracking;

  const popTrackedFood = (food) => {
    const foodId = food.key;
    context.setFoodListForTracking(
      context.foodListForTracking.filter((food) => food.key !== foodId)
    );
  };

  return (
    <>
      {trackedFoodItems.length <= 0 && (
        <Box
        className="butthole"
          sx={{
            display: "flex",
            justifyContent: "center",
            height: "100%",
            alignItems:"center"
          }}
        >
          <Typography>What you ate today will appear here!</Typography>
        </Box>
      )}
      {trackedFoodItems.map((foodItem) => {
        console.log(foodItem)
        const controls = foodItem.key + "-content";
        const id = foodItem.key + "-header";
        return (
          <Box key={foodItem.key}>
            <Accordion
              sx={{
                borderRadius: "0px !important",
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={controls}
                id={id}
              >
                <Typography>{foodItem.description}</Typography>
              </AccordionSummary>
              <AccordionDetails
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  borderRadius: "0px",
                }}
              >
                <Typography>
                  Calories: {foodItem.calories} kcals | Fat:{" "}
                  {foodItem.fatFormat} | Carbs:
                  {foodItem.carbsFormat} | Protein: {foodItem.proteinFormat}
                </Typography>
                <Button
                  type="submit"
                  color="secondary"
                  variant="contained"
                  size="small"
                  sx={{ color: "primary" }}
                  onClick={() => {
                    popTrackedFood(foodItem);
                  }}
                >
                  Remove
                </Button>
              </AccordionDetails>
            </Accordion>
          </Box>
        );
      })}
    </>
  );
};

export default TrackedFoodList;
