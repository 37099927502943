import { Grid, CssBaseline, Paper } from "@mui/material";
import ProfileDetails from "../Components/ProfileComponents/ProfileDetails";
import FoodLog from "../Components/ProfileComponents/FoodLog";
import { useState, useContext } from "react";
import { UserContext, CalorieContext } from "../Context/Context";

const ProfilePage = () => {
  const context = useContext(UserContext);
  const [calories, setCalories] = useState(context.userDetails.BMR);

  return (
    <CalorieContext.Provider value={{ calories, setCalories }}>
      <Grid
        container
        component="main"
        sx={{
          pt: 3,
          justifyContent: "space-evenly",
        }}
      >
        <CssBaseline />
        <Grid item xs={12} md={4} sx={{
          justifyContent: "space-evenly",
        }}>
          <ProfileDetails />
        </Grid>

        <Grid item xs={12} md={8} sx={{}}>
          <FoodLog />
        </Grid>
      </Grid>
    </CalorieContext.Provider>
  );
};

export default ProfilePage;
