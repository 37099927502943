import { getAuth } from "firebase/auth";
import { MyRTDS } from "../../FireBaseValues";
import { useState, useContext } from "react";
import { ref, child, get } from "firebase/database";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { themeOptions } from "../../themeOptions";
import { createTheme } from "@mui/material";
import {
  Typography,
  Divider,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Paper,
  CircularProgress,
  Grid,
  Box,
} from "@mui/material";
import { CalorieContext } from "../../Context/Context";

const FoodLog = () => {
  const theme = createTheme(themeOptions);
  const calContext = useContext(CalorieContext);
  const [foodLog, setFoodLog] = useState();
  const [loading, setloading] = useState(true);
  const [gotLog, setGotLog] = useState(false);
  const user = getAuth().currentUser;
  const dbRef = ref(MyRTDS);

  //check if we have already fetched the user's log, if not fetch the log from the RTDS
  if (!gotLog) {
    get(child(dbRef, "users/" + user.uid))
      .then((snapshot) => {
        const logItems = [];
        if (snapshot.exists()) {
          const snapshotObject = snapshot.val();
          for (let item in snapshotObject) {
            logItems.push(snapshotObject[item]);
          }
          setFoodLog(logItems.reverse());
          setGotLog(true);
          setloading(false);
        } else {
          setGotLog(false);
          setloading(false);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }

  return (
    <Paper
      sx={{
        mx: 1,
        mb: 2,
        height: "auto",
        maxHeight: "80vh",
        overflow: "auto",
      }}
    >
      <Divider />
      {gotLog &&
        !loading &&
        foodLog.map((logItem, index) => {
          const logItemFood = logItem.userFoods;
          const controls = logItem.id + "-content";
          const id = logItem.id + "-header";
          return (
            <Accordion key={index}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={controls}
                id={id}
                sx={{
                  p: 3,
                }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={12} md={4}>
                    <strong>{logItem.day}</strong>
                  </Grid>
                  <Grid item xs={12} md={8}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={2} sx={{ mr: 1 }}>
                        <Typography sx={{ fontWeight: "bold", mr: 1 }}>
                          Items:
                        </Typography>
                        <Typography sx={{ display: "inline-block" }}>
                          {logItem.userFoods.length}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <Typography sx={{ fontWeight: "bold", mr: 1 }}>
                          Total Calories:
                        </Typography>
                        <Typography sx={{ display: "inline-block" }}>
                          {logItem.userCalories}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <Typography sx={{ fontWeight: "bold", mr: 1 }}>
                          <Typography sx={{ fontWeight: "bold", mr: 1 }}>
                            Calories leftover:
                          </Typography>
                          <Typography sx={{ display: "inline-block" }}>
                            {calContext.calories - logItem.userCalories}
                          </Typography>
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Divider py={2} />
              </AccordionSummary>
              <AccordionDetails sx={{}}>
                {gotLog &&
                  logItemFood.map((food, index) => {
                    return (
                      <>
                        <Grid key={index} container spacing={2}>
                          <Grid item xs={12} md={4} mt={2}>
                            <Typography
                              sx={{
                                fontWeight: "bold",
                                color: "#609BC5",
                                textAlign: "center",
                              }}
                            >
                              {food.description}
                            </Typography>
                          </Grid>
                          <Grid item xs={12} md={8} mb={1}>
                            <Grid container spacing={2}>
                              <Grid item xs={12} md={6}>
                                <Typography
                                component=""
                                  sx={{ fontWeight: "bold", color: "#FFB453" }}
                                >
                                  Calories:
                                </Typography>
                                <Typography ml={1}>
                                  {food.calories} kcals
                                </Typography>
                              </Grid>
                              <Grid item xs={12} md={6}>
                                <Typography
                                  sx={{ fontWeight: "bold", color: "#FFB453" }}
                                >
                                  Fat:
                                </Typography>
                                <Typography ml={1}>{food.fatFormat}</Typography>
                              </Grid>
                              <Grid item xs={12} md={6}>
                                <Typography
                                  sx={{ fontWeight: "bold", color: "#FFB453" }}
                                >
                                  Carbs:
                                </Typography>
                                <Typography ml={1}>
                                  {food.carbsFormat}
                                </Typography>
                              </Grid>
                              <Grid item xs={12} md={6}>
                                <Typography
                                  sx={{ fontWeight: "bold", color: "#FFB453" }}
                                >
                                  Protein:
                                </Typography>
                                <Typography ml={1}>
                                  {food.proteinFormat}
                                </Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Divider />
                      </>
                    );
                  })}
              </AccordionDetails>
            </Accordion>
          );
        })}
      {loading && <CircularProgress />}
      {!gotLog && !loading && (
        <Box>
          <Typography component={"h2"}>
            Couldn't find your food log, try adding some meals on the tracker
            page
          </Typography>
        </Box>
      )}
    </Paper>
  );
};

export default FoodLog;
