export const themeOptions = {
  palette: {
    type: "light",
    primary: {
      main: "#609BC5",
    },
    secondary: {
      main: "#FFB453",
    },
    background: {
      default: "#e7f2fa",
    },
    custom: {
      main: "#609BC5",
    },
  },
  typography: {
    fontFamily: ["Poppins"],
  },
};
